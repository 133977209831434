<template>
  <v-list-item>
    <v-badge
      bottom
      dot
      overlap
      offset-x="16"
      offset-y="16"
      :color="badgeColor"
      :title="badgeTitle"
    >
      <v-list-item-avatar size="32" style="margin-left: 0">
        <v-img :src="user.avatar.url"></v-img>
      </v-list-item-avatar>
    </v-badge>

    <v-list-item-content>
      <v-list-item-title>{{ user.name }}</v-list-item-title>
    </v-list-item-content>

    <v-list-item-action v-if="showDeleteButton">
      <temeno-circle-button title="löschen" @click="$emit('delete', user)">
        $delete
      </temeno-circle-button>
    </v-list-item-action>

    <v-list-item-action>
      <temeno-circle-button
        :title="
          user.favorite ? 'aus Favoriten entfernen' : 'als Favorit markieren'
        "
        @click="toggleFavorite"
      >
        {{ user.favorite ? "$favorite_green" : "$favorite_white" }}
      </temeno-circle-button>
    </v-list-item-action>

    <v-list-item-action>
      <temeno-circle-button title="Videoanruf starten" @click="startVideoCall">
        $video_call
      </temeno-circle-button>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import api from "@/api";
import { AVAILABLE, AWAY, BUSY, IDLE } from "@/status";

export default {
  name: "NavigationUserListItem",
  props: {
    deleteButton: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
    },
  },
  components: {
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  computed: {
    badgeColor() {
      const { status } = this.user;
      switch (status) {
        case AVAILABLE:
          return "green";
        case AWAY:
          return "yellow";
        case BUSY:
          return "red";
        case IDLE:
        default:
          return "grey";
      }
    },
    badgeTitle() {
      const { status } = this.user;
      return this.$t("status." + status);
    },
    showDeleteButton() {
      return this.deleteButton && this.$store.getters['user/isWorkspaceAdmin'];
    },
  },
  methods: {
    startVideoCall() {
      console.log("starting video call with", this.user);
      const conference = {
        subject: "Direktanruf",
        participants: [
          {
            scope: "JOIN",
            type: "user",
            userId: this.user.id,
          },
        ],
      };
      api.createConference(conference).then((response) => {
        const { location } = response.headers;
        const id = Number(location.substring(location.lastIndexOf("/") + 1));
        api.inviteForConference(id).then(() => {
          this.$router.push({
            name: "conference",
            params: {
              id,
            },
          });
        });
      });
    },
    toggleFavorite() {
      this.$store.dispatch("users/toggleFavorite", this.user);
    },
  },
  i18n: {
    messages: {
      de: {
        status: {
          [AVAILABLE]: "verfügbar",
          [AWAY]: "abwesend",
          [BUSY]: "beschäftigt",
          [IDLE]: "offline",
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.theme--dark {
  &.v-list-item {
    // padding: 0 6px;

    .v-list-item__action {
      margin: 6px 0;
      display: none;
    }

    .v-list-item__avatar:first-child {
      margin-right: 10px;
    }

    &:hover {
      .v-list-item__action {
        display: inline-flex;
      }
    }
  }
}
</style>
